import { useIntl, Link } from "gatsby-plugin-intl"
import { defaultIntl, Slider } from "../../utils"
import Layout from "../../components/layout"
import React, { useEffect, useState, useContext } from "react"
import "../../includes/jobs/jobs.sass"
import { $get } from "../../components/forms"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { globalContext } from "../../components/context"

const SliderData = [
  {
    id: 0,
    title: "“团队合作共赢” —— 饭团员工合影",
    title_en: '"Together as a team" —— Fantuan Staff Group Photo',
    title_fr: "Ensemble en équipe - Photo de groupe du personnel de Fantuan",
    imgUrl: "pic1",
    summary:
      "由多块木板构成的木桶，其价值在于盛水量的多少，但决定木桶盛水量多少的关键因素不是其最长的版块，而是其最短的版块。饭团的发展离不开每一位员工，正是大家一起的辛勤付出，才能使团队共赢。",
    summary_en:
      "The Wooden Bucket Theory (Cannikin Law). The fundamental idea of the Cannikin Law, looks at the importance of the construction of each wooden plank that makes up a bucket. The capacity of water the bucket can contain is based on the shortest plank. The Cannikin Law is applied to the growth of our team at Fantuan. The hard work and preservation of our team is what brings the success of Fantuan today.",
    summary_fr:
      "La théorie Wooden Bucket Theory (Loi de Cannikin). L'idée fondamentale de la Loi Cannikin, regarde l'importance de la construction de chaque planche de bois qui compose un seau. La capacité d'eau que le seau peut contenir est basée sur la planche la plus courte. La loi Cannikin est appliquée à la croissance de notre équipe chez Fantuan. Le travail acharné et la préservation de notre équipe sont ce qui fait le succès de Fantuan aujourd'hui.",
  },
  {
    id: 1,
    title: "“融合、成长” —— 饭团总部2019年会",
    title_en: '"Integration and Growth" —— 2019 Fantuan Headquarter’s Annual Meeting',
    title_fr: "Intégration et croissance - Réunion annuelle 2019 du siège social de Fantuan",
    imgUrl: "pic2",
    summary: "这是饭团成立的第5年，平均年龄不达27的饭团员工们在融合中成长，在成长中融合，共同谱写更辉煌的未来。",
    summary_en:
      "This is the 5th year since Fantuan has been established, the average age of the staff at Fantuan is 27. Throughout the years, our team has been continuously growing and developing; On the path of creating a brilliant future.",
    summary_fr:
      "Fantuan existe depuis 5 ans, la moyenne d’âge du personnel de Fantuan est de 27 ans. Au fil des ans, notre équipe n'a cessé de croître et de se développer. L’avenir est prometteur pour Fantuan!",
  },
  {
    id: 2,
    title: "“切磋球技，共同提高” —— 饭团员工第一届篮球大赛",
    title_en: '"Learn from each others strengths, Improve as a team" —— The 1​st​ Fantuan Staff Basketball Tournament',
    title_fr: "Apprenez des forces de chacun, améliorez-vous en équipe - Le 1er tournoi de basket-ball des employés de Fantuan",
    imgUrl: "pic3",
    summary: "在篮球场上，每一位饭团员工都敢于拼搏，永不放弃。在工作之余，饭团每个季度都会组织员工团建来释放工作压力以及提升团队凝聚力。",
    summary_en:
      "Aside from work, Fantuan will organize team-building events every quarter to help employees release work pressure. The basketball court is used as an exercise for Fantuan staff to strive for success, and never give up; while learning how to build a strong and successful team.",
    summary_fr:
      "À côté du travail, Fantuan organisera des événements de team building chaque trimestre pour aider les employés à relâcher la pression du travail. Le terrain de basket est utilisé comme exercice par le personnel de Fantuan pour viser le succès et ne jamais abandonner, tout en apprenant comment construire une équipe solide et performante.",
  },
]

export default () => {
  const intl = useIntl() || defaultIntl
  const [slider, setSlider] = useState(null)
  const [posCount, setPostCount] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [showItem] = useState(10)
  const [lang] = useState(intl.locale)
  const [current, setCurrent] = useState(1)
  const [page, setPage] = useState(0)
  const { country } = useContext(globalContext)

  const data = useStaticQuery(graphql`
    query {
      pic1: file(relativePath: { eq: "jobs/job-banner1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic2: file(relativePath: { eq: "jobs/job-banner2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic3: file(relativePath: { eq: "jobs/job-banner3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const QueryOption = () => `&_sort=top:ASC,hot:DESC`
  const loadMore = () => {
    setIsLoading(true)
  }
  const handleLoad = () => {
    $get(`/job-posts?${QueryOption()}`)
      .then(({ data }) => {
        if (data && data.length) {
          setList([...list, ...data])
          setCurrent(current + 1)
        }
        if (data.length < showItem) {
          setFinished(true)
        }
      })
      .catch(e => {
        alert(e.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const getCount = () => {
    $get(`/job-posts/count`).then(({ data }) => {
      setPostCount(data)
    })
  }

  useEffect(() => {
    handleLoad()
    getCount()
  }, [])

  useEffect(() => {
    if (isLoading) {
      handleLoad()
    }
  }, [isLoading])

  useEffect(() => {
    setPage((current - 1) * showItem)
  }, [current])

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: cur => {
      setCurrentSlide(cur)
    },
  }

  const handlerSwitch = i => {
    let item = document.querySelector(".current")
    if (item) {
      item.classList.remove("current")
    }
    slider.slickGoTo(i)
  }

  return (
    <Layout intl={intl} page="jobs">
      <div className="jobs section">
        <div className="container">
          <div className="section-title">{intl.formatMessage({ id: "jobs.jobdevelop" })}</div>
          <div className="jobs-banner">
            <Slider {...settings} ref={slider => setSlider(slider)}>
              {SliderData.map((item, index) => {
                return (
                  <div className="slide-item" key={index}>
                    <Img fluid={data[item.imgUrl].childImageSharp.fluid} src={item.imgUrl} alt="" />
                    <div className="slide-content">
                      <div className="slide-title">{lang === "zh" ? item.title : item[`title_${lang}`]}</div>
                      <div className="slide-subtitle">{lang === "zh" ? item.summary : item[`summary_${lang}`]}</div>
                    </div>
                  </div>
                )
              })}
            </Slider>
            <div className="slider-controll">
              {SliderData.map((s, i) => {
                const sliderClass = ["slider-indicator"]
                if (i === currentSlide) {
                  sliderClass.push("current")
                }
                return <span className={sliderClass.join(" ")} onClick={() => handlerSwitch(i)} onMouseOver={() => handlerSwitch(i)} key={i} />
              })}
            </div>
          </div>
          <div className="jobs-list">
            <nav className="panel">
              <div className="panel-heading" style={{ border: "none" }}>
                {intl.formatMessage({ id: "jobs.heading" })}
                <div className="panel-head-desc">
                  <span className="numb">{list ? list.filter(item => !item.hidden).length : 0}</span>
                  {intl.formatMessage({ id: "jobs.headdesc" })}
                </div>
              </div>
              <div className="panel-block" />
              {list
                .filter(item => {
                  const title = intl.locale === "zh" ? "title" : "title_en"
                  return item[title] && !item.hidden
                })
                .map(item => (
                  <Link to={`/jobs/detail?job_id=${item.id}&country=${country}`} className="panel-block" key={item.id}>
                    <div style={{ width: "50%" }}>
                      <span className="job-title">
                        <span>{lang === "zh" ? item.title : item[`title_${lang}`]}</span>
                        {item.hot ? <i className="hot">HOT</i> : null}
                      </span>
                    </div>
                    <div style={{ width: "40%" }}>
                      <span className="job-location">{item.city}</span>
                    </div>
                    <div style={{ width: "10%" }} className="view">
                      <span className="view-more">
                        {intl.formatMessage({ id: "jobs.viewdetail" })}
                        <i className="material-icons">chevron_right</i>
                      </span>
                    </div>
                  </Link>
                ))}
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  )
}
